import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input, Select, Button, DatePicker} from "antd";
import moment from "moment";
import {SearchOutlined, CloseOutlined} from "@ant-design/icons";

export const defaultAuditReportFilters = {transaction_type: '', date_range: null, company_name:'',user_name:'',no_of_licenses:''};

const AuditReportFilter = (props) => {
  const [form] = Form.useForm();

  const onSubmitClick = values => {
    props.onSubmit(values);
  };
  const onResetClick = () => {
    form.setFieldsValue(defaultAuditReportFilters);
    props.onSubmit(defaultAuditReportFilters);
  }

  const Validations = {
    noOfLicenses: [{min: 1, max: 9999, message: 'No of linceses must be between 1 and 9999'}]
  };

  return (
    <Form layout='vertical' onFinish={onSubmitClick} initialValues={props.initialValues} form={form}>
      <Form.Item name='date_range' label='Transaction Date'>
        <DatePicker.RangePicker format="YYYY-MM-DD" disabledDate={(current) => current && current > moment().endOf('day')}
                     style={{width: '100%'}}/>
      </Form.Item>

      <Form.Item name='transaction_type' label='Transaction Type'>
        <Select placeholder='Select transaction type' allowClear>
          <Select.Option value='CR'>Credit</Select.Option>
          <Select.Option value='DR'>Debit</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item name='no_of_licenses' label='No of Licenses' rules={Validations.noOfLicenses}>
        <Input type='number' placeholder='No of licenses for transaction'/>
      </Form.Item>

      <Form.Item name='company_name' label='Company Name'>
        <Input placeholder='Enter company name'/>
      </Form.Item>
      <Form.Item name='user_name' label='Transaction By'>
        <Input placeholder='Enter name of person who did transaction'/>
      </Form.Item>

      <Form.Item>
        <Button type='default' htmlType='Submit'><SearchOutlined/> Submit</Button>
        <Button type='default' htmlType='button' onClick={onResetClick}><CloseOutlined/> Reset</Button>
      </Form.Item>

    </Form>
  );
};

AuditReportFilter.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
}
export default AuditReportFilter;
