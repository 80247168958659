import {
  PENDING_APPROVAL_COUNT,
  RECENT_ACTIVITIES,
  RECENT_TRANSACTIONS,
  REMAINING_LICENSE_BALANCE,
  SIGNOUT_USER_SUCCESS
} from "../../constants/ActionTypes";

const INITIAL_STATE = {
  licenseBalance: 0,
  pendingApprovals: 0,
  activities: [],
  transactions: []
};

export default (state = INITIAL_STATE, {type, payload}) => {
  switch (type) {
    case REMAINING_LICENSE_BALANCE: {
      return {...state, licenseBalance: payload};
    }

    case PENDING_APPROVAL_COUNT: {
      return {...state, pendingApprovals: payload};
    }

    case RECENT_ACTIVITIES: {
      return {...state, activities: payload};
    }

    case RECENT_TRANSACTIONS: {
      return {...state, transactions: payload};
    }

    case SIGNOUT_USER_SUCCESS: {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
}
