import React, {useEffect} from "react";
import {Button, Form, Input, Row} from "antd";
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {userSignIn} from "../../appRedux/actions";
import IntlMessages from "util/IntlMessages";
import InfoView from "../../components/InfoView";
import './LoginSignup.css';
import {emailRules} from "../../constants/Validations";

const SignIn = (props) => {

  const dispatch = useDispatch();
  const token = useSelector(({auth}) => auth.token);

  const onFinish = values => {
    dispatch(userSignIn(values));
  };

  useEffect(() => {
    document.body.style.backgroundImage = "url(/images/background.jpg)";
    document.body.style.backgroundSize = "cover";

    return () => {
      document.body.style.background = '';
    };
  }, []);

  useEffect(() => {
    if (token !== null) {
      props.history.push('/');
    }
  }, [token, props.history]);

  const Validations = {
    email: emailRules,
    password: [
      {required: true, message: 'Please enter your password'},
      // passwordRegex
    ]
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-login-content">
            <div className="gx-app-logo">
              <img alt="Xtreme Media logo" src={require('assets/images/logo-white.svg')}/>
            </div>

            <Form
              initialValues={{remember: true}}
              name="login_form"
              onFinish={onFinish}
              className="gx-signin-form gx-form-row0" autoComplete={'off'}>

              <Form.Item rules={Validations.email} name="email">
                <Input placeholder="Email"/>
              </Form.Item>

              <Form.Item rules={Validations.password} name="password">
                <Input type='password'  placeholder="Password" autoComplete='off'/>
              </Form.Item>

              <Row type="flex" justify="end" className="gx-mb-4">
                <Link to="/forgot-password">Forgot Password?</Link>
              </Row>

              <Form.Item>
                <Row type='flex' justify='center'>
                  <Button type="primary" className="gx-mb-0" htmlType="submit" style={{width: '50%'}}>
                    <IntlMessages id="app.userAuth.signIn"/>
                  </Button>
                </Row>
              </Form.Item>

            </Form>
          </div>
          <InfoView/>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
