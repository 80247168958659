import {
  CLEAR_CREATE_USER_ERRORS,
  CREATE_USER_COMPLETE,
  CREATE_USER_FAILED,
  CREATE_USER_START,
  FETCH_USERS_START,
  FETCH_USERS_COMPLETE,
  FETCH_USERS_LIST,
  USERS_PAGE_FILTERS_CHANGE,
  SIGNOUT_USER_SUCCESS, CREATE_USER_SUCCESS, UPDATE_PROFILE_LOADING, PROJECT_MANAGERS_LIST, TRANSFER_LOADING
} from "../../constants/ActionTypes";
import {defaultUserFilters} from "../../routes/UserPage/UserFilters";

const InitialState = {
  createUser: {
    loading: false,
    errors: []
  },
  loading: false,
  page: 1,
  results: 10,
  total: 10,
  list: [],
  filters: defaultUserFilters,
  updateData: 0,
  profileUpdate: {loading:false, errors:[]},
  transferUser: {userList:[], loading:false}
};

export default (state = InitialState, {type, payload}) => {
  switch (type) {
    case CREATE_USER_START:
      return {...state, createUser: {...state.createUser, loading: true}};
    case CREATE_USER_COMPLETE:
      return {...state, createUser: {...state.createUser, loading: false}};

    case CREATE_USER_FAILED:
      return {...state, createUser: {...state.createUser, errors: payload}};
    case CREATE_USER_SUCCESS:
      return {...state, updateData: (state.updateData+1)};

    case CLEAR_CREATE_USER_ERRORS:
      return {...state, createUser: {...state.createUser, errors: []}};

    case FETCH_USERS_START:
      return {...state, loading: true};
    case FETCH_USERS_COMPLETE:
      return {...state, loading: false};
    case FETCH_USERS_LIST:
      return {...state, list: payload.data, total: payload.total};

    case USERS_PAGE_FILTERS_CHANGE:
      return {...state, page: payload.page, results: payload.results, filters: payload.filters};

    case UPDATE_PROFILE_LOADING:
      return {...state, profileUpdate: {...state.profileUpdate, loading: payload}};

    case PROJECT_MANAGERS_LIST: {
      return {...state, transferUser: {...state.transferUser, userList: payload}};
    }
    case TRANSFER_LOADING: {
      return {...state, transferUser: {...state.transferUser, loading: payload}};
    }

    case SIGNOUT_USER_SUCCESS:
      return InitialState;

    default:
      return state;
  }
};
