import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form, Input, Row, Select, DatePicker} from "antd";
import {CloseOutlined, SearchOutlined} from "@ant-design/icons";


const {RangePicker} = DatePicker;

export const defaultActivityFilters = {user_name: '', section: '', message: '', date_range: null, ip: '', user_type_id: ''};

const ActivityFilters = props => {
  const [form] = Form.useForm();

  const onSubmit = values => {
    props.onSubmit(values);
  };

  const onReset = () => {
    form.setFieldsValue(defaultActivityFilters);
    props.onSubmit(defaultActivityFilters);
  };

  return (
    <Form name='filter-form' form={form} layout='vertical' onFinish={onSubmit}
          initialValues={props.initialValues}>
      <Form.Item name='user_name' label='User Name'>
        <Input placeholder='Enter user name here'/>
      </Form.Item>

      {
        [1, 2, 3].includes(props.userTypeId) &&
        <Form.Item name='user_type_id' label='User Type'>
          <Select placeholder='Select user type' allowClear>
            {props.userTypes.map((item, index) => (
              <Select.Option key={index} value={item.user_type_id}>{item.user_type}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      }

      <Form.Item name='section' label='Section'>
        <Input placeholder='Enter section here'/>
      </Form.Item>

      <Form.Item name='message' label='Activity'>
        <Input placeholder='Enter message here'/>
      </Form.Item>

      <Form.Item name='date_range' label='Date Range' rules={[{type: 'array', message: 'Please select time!',},]}>
        <RangePicker showTime format="YYYY-MM-DD HH:mm"/>
      </Form.Item>

      <Form.Item name='ip' label='IP'>
        <Input placeholder='Enter IP here'/>
      </Form.Item>

      <Row>
        <Col>
          <Button type='default' htmlType='submit'><SearchOutlined/>Search</Button>
          <Button type='default' htmlType='button' onClick={onReset}><CloseOutlined/>Reset</Button>
        </Col>
      </Row>
    </Form>
  );
};

ActivityFilters.defaultProps = {
  initialValues: {}
};

ActivityFilters.propTypes = {
  initialValues: PropTypes.object.isRequired,
  userTypeId: PropTypes.number.isRequired,
  userTypes: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ActivityFilters;
