import axios from '../../util/Api';
import {history} from "../store";
import {message} from "antd";
import {
  CHANGE_PARTNER_DETAILS_DRAWER_VISIBILITY,
  CLEAR_PARTNER_ERROR,
  CREATE_PARTNER_ERROR,
  FETCH_PARTNER_LIST,
  FETCH_PARTNER_LIST_COMPLETE,
  FETCH_PARTNER_LIST_START, LICENSE_APPROVAL_COMPLETE, PARTNER_DETAILS_DATA, PARTNER_DETAILS_LOADER,
  PARTNER_FILTER_CHANGE,
  UPDATE_LICENSES_COMPLETE,
  UPDATE_LICENSES_DRAWER_VISIBILITY,
  UPDATE_LICENSES_START, UPDATE_LICENSES_SUCCESS
} from "../../constants/ActionTypes";

export const createPartner = data => (dispatch) => {
  axios.post('/partner', data).then(() => {
    message.success('Partner created successfully');
    history.push('/partners');
  }).catch(error => {
    if (error.response && error.response.status === 422) {
      console.log('Validation error: ', error.response.data.data.errors);
      dispatch({type: CREATE_PARTNER_ERROR, payload: error.response.data.data.errors})
    } else {
      console.log('Internal server error', error);
      message.error('Something went wrong');
    }
  });
};

export const clearCreatePartnerError = () => {
  return {type: CLEAR_PARTNER_ERROR};
};

export const fetchPartnerList = (page, results, filters = {}) => dispatch => {
  dispatch({type: FETCH_PARTNER_LIST_START})
  axios.get('/partner-list', {params: {page, results, filters}}).then(({data}) => {
    dispatch({type: FETCH_PARTNER_LIST, payload: data});
    dispatch({type: FETCH_PARTNER_LIST_COMPLETE})
  }).catch(() => {
    dispatch({type: FETCH_PARTNER_LIST_COMPLETE});
    dispatch({type: FETCH_PARTNER_LIST, payload: {total: 0, data: []}});
  });
};

export const filtersChanges = (page, results, filters) => {
  return {type: PARTNER_FILTER_CHANGE, payload: {page, results, filters}};
};

export const updateLicenseUpdateDrawerVisibility = status => {
  return {type: UPDATE_LICENSES_DRAWER_VISIBILITY, payload: status};
}

export const updateLicense = data => dispatch => {
  dispatch({type: UPDATE_LICENSES_START});

  axios.post('/update-partner-license', data).then(() => {
    dispatch({type: UPDATE_LICENSES_COMPLETE});
    dispatch({type: UPDATE_LICENSES_SUCCESS});

    message.success('No of licenses updated successfully');
  }).catch(error => {
    dispatch({type: UPDATE_LICENSES_COMPLETE});
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.data.errors[0].msg);
    } else {
      message.error('Something went wrong with the server');
    }
  })
};

export const changePartnerDetailsDrawerVisibility = (status => {
  return {type: CHANGE_PARTNER_DETAILS_DRAWER_VISIBILITY, payload: status};
});

export const fetchPartnerDetails = (partner_id) => dispatch => {
  dispatch({type: PARTNER_DETAILS_LOADER, payload: true});

  axios.get(`/partner-approval-details`, {params: {partner_id}}).then(({data}) => {
    dispatch({type: PARTNER_DETAILS_LOADER, payload: false});
    dispatch({type: PARTNER_DETAILS_DATA, payload:data});
  }).catch(error => {
    dispatch({type: PARTNER_DETAILS_LOADER, payload: false});
    console.error('Error while fetching partner details: ', error);
    console.log(error.message);
  })
};

export const updatePartnerLicenseApprovalStatus = data => dispatch => {
  axios.post('/approve-partner-license',data).then(() => {
    dispatch({type: LICENSE_APPROVAL_COMPLETE});
    message.success('Status update successfully.');
  }).catch(error => {
    console.log(error.message);
    if (error.response && error.response.status === 422) {
      message.error(error.response.data.data.errors[0].msg);
    } else {
      message.error(error.message);
    }
  });
};



