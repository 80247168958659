import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import EmailForm from "./EmailForm";
import {Steps, Row} from "antd";
import {Link} from "react-router-dom";
import {MailOutlined, LockOutlined} from '@ant-design/icons';
import VerifyCode from "./VerifyCode";
import {sendOTP, changeEmail, resetPassword} from "../../../appRedux/actions";


const ForgotPassword = () => {
  // const [currentStep, setCurrentStep] = useState(1);
  const [email, setEmail] = useState('');
  // const [loading, setLoading] = useState('');
  const dispatch = useDispatch();

  const state = useSelector(({auth}) => {
    return {
      emailFormLoader: auth.forgotPassword.emailFormLoading,
      currentStep: auth.forgotPassword.step
    }
  })

  useEffect(() => {
    document.body.style.backgroundImage = "url(/images/background.jpg)";
    document.body.style.backgroundSize = "cover";

    return () => {
      document.body.style.background = '';
      dispatch(changeEmail());
    };
  }, []);

  const sendOtpForEmail = email => {
    dispatch(sendOTP(email));
  };

  const onSendOtpClick = (values) => {
    setEmail(values.email);
    sendOtpForEmail(values.email);
  };

  const onPasswordChange = values => {
    console.log('change password: ', values);
    dispatch(resetPassword({email_id:email, ...values}));
  };
  const onEditEmailClick = () => dispatch(changeEmail());

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-login-content">
            <div className="gx-app-logo">
              <img alt="Xtreme Media logo" src={require('../../../assets/images/logo-white.png')}/>
            </div>

            <Steps current={state.currentStep}>
              <Steps.Step title="Email" icon={<MailOutlined/>}/>
              <Steps.Step title="Verify OTP" icon={<LockOutlined/>}/>
            </Steps>

            <div className="steps-content">
              {state.currentStep === 0 ?
                <EmailForm initialValues={{email}} onSubmit={onSendOtpClick} loading={state.emailFormLoader}/>
                :
                <VerifyCode email={email} onSubmit={onPasswordChange} onEditEmail={onEditEmailClick}
                            onResendOTP={() => sendOtpForEmail(email)}/>
              }

            </div>

            <Row type="flex" justify="center">
              <div>
                <Link to="/signin">Back to Sign In</Link>
              </div>
            </Row>
          </div>
        </div>
      </div>

    </div>
  );
};

export default ForgotPassword;
