import axios from '../../util/Api';
import {PLAYER_PASSWORD_ERRORS, ClearPlayerPasswordErrors} from "../../constants/ActionTypes";
import {message} from "antd";

export const sendPassword = data => async (dispatch) => {
  axios.post('/replace-password',data).then(response => {
    message.success('Password has been sent on provided email address.');
    dispatch({type: ClearPlayerPasswordErrors});
  }).catch(error => {
    if (error.response && error.response.status === 422) {
      const errorResponse = error.response.data;
      if (errorResponse.data && errorResponse.data.errors && errorResponse.data.errors.length > 0) {
        dispatch({type:PLAYER_PASSWORD_ERRORS, payload: errorResponse.data.errors});
      }
    } else {
      message.error('Something went wrong');
      console.error('Error while adding amc to license: ', error);
    }
  });
};

export const clearPlayerPasswordErrors = () => {
  return {type: ClearPlayerPasswordErrors};
}
