import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

const ResendOtp = props => {
  let resendOtpTimerInterval = null;
  const OTP_RESEND_TIME = 30;
  const [resendOtpTimer, setResendOtpTimer] = useState(OTP_RESEND_TIME);

  const startResendOtpTimer = () => {
    if (resendOtpTimerInterval) {
      clearInterval(resendOtpTimerInterval);
    }

    resendOtpTimerInterval = setInterval(() => {
      if (resendOtpTimer <= 0) {
        clearInterval(resendOtpTimerInterval);
      } else {
        setResendOtpTimer(resendOtpTimer - 1);
      }
    }, 1000);
  };

  const onResendOtp = async () => {

    await props.onResend();
    setResendOtpTimer(OTP_RESEND_TIME);
  };

  useEffect(() => {
    startResendOtpTimer();
    return () => {
      if (resendOtpTimerInterval) {
        clearInterval(resendOtpTimerInterval);
      }
    }
  });

  return (

    <div style={{fontSize: '14px', float: 'right',marginTop:'-30px'}}>
      {
        resendOtpTimer > 0 ?
          <span>Resend OTP in {resendOtpTimer} seconds</span>
          :
          <span>Didn't receive the OTP yet? <a onClick={onResendOtp}>Resend OTP</a></span>
      }

    </div>

  );
};

ResendOtp.propTypes = {
  onResend: PropTypes.func.isRequired
};
export default ResendOtp;
