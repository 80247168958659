import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input, Button, Row} from "antd";
import ResendOtp from "./ResendOtp";
import {passwordRegex} from "../../../constants/Validations";

const VerifyCode = props => {
  const onResendOtp = () => {
    props.onResendOTP();
  };

  const onSubmit = values => {
    props.onSubmit({otp: values.otp, password: values.password});
  };

  const Validation = {
    otp: [
      {required: true, message: 'Otp is required'},
      {pattern: new RegExp(/^\d{6}$/), message: 'Otp must be 6 digit number'}
    ],
    password: [{required: true, message: 'Password is required'}, passwordRegex,],
    confirm: [{required: true, message: 'Confirm password is required'},
      ({getFieldValue}) => ({
        validator(rule, value) {
          if (!value || getFieldValue('password') === value) {
            return Promise.resolve();
          }
          return Promise.reject('Confirm password does not match with password');
        },
      }),]
  };

  return (
    <div className="gx-my-3 gx-mx-2">
      <p>Verification code has been sent to your email id</p>

      <Form onFinish={onSubmit} name='change-password'>
        <Form.Item name='otp' rules={Validation.otp}>
          <Input placeholder="Enter OTP" type='number'/>
        </Form.Item>
        <Form.Item>
          <div><ResendOtp onResend={onResendOtp}/></div>
        </Form.Item>

        <Form.Item name='password' rules={Validation.password} style={{marginTop: '-30px'}}>
          <Input.Password placeholder="Enter New Password" autoComplete='on'/>
        </Form.Item>
        <Form.Item name='confirm-password' dependencies={['password']} rules={Validation.confirm}>
          <Input.Password placeholder="Confirm Password" autoComplete='on'/>
        </Form.Item>

        <Form.Item>
          <Row type="flex" justify="center">
            <div>
              <p>Not <span style={{color: '#e14504'}}>{props.email}</span>?
                <span style={{marginLeft: 8, textDecoration: 'underline', cursor: 'pointer'}}
                      onClick={props.onEditEmail}>Edit Email</span>
              </p>
            </div>
          </Row>
          <Button style={{float: 'right'}} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

VerifyCode.propTypes = {
  email: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onEditEmail: PropTypes.func.isRequired,
  onResendOTP: PropTypes.func.isRequired
};
export default VerifyCode;
