import axios from '../../util/Api';
import {
  CHANGE_PASSWORD_LOADER,
  CHANGE_STEP,
  INIT_URL,
  SET_EMAILFORM_LOADER, SET_PSWD_LOADER,
  SIGNOUT_USER_SUCCESS, UNSET_EMAILFORM_LOADER, UNSET_PSWD_LOADER, UPDATE_PROFILE_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET
} from "../../constants/ActionTypes";

const INIT_STATE = {
  token: JSON.parse(localStorage.getItem('token')),
  initURL: '',
  authUser: JSON.parse(localStorage.getItem('user')),
  forgotPassword: {emailFormLoading: false, step: 0, pswdChangeLoader: false},
  changePassword: {loading: false}
};

if (INIT_STATE.token) {
  axios.defaults.headers.common['Authorization'] = (INIT_STATE.token);
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case INIT_URL: {
      return {...state, initURL: action.payload};
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        authUser: null,
        initURL: '',
        forgotPassword: INIT_STATE.forgotPassword
      }
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      };
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
      };
    }

    case SET_EMAILFORM_LOADER:
      return {...state, forgotPassword: {...state.forgotPassword, emailFormLoading: true}};
    case UNSET_EMAILFORM_LOADER:
      return {...state, forgotPassword: {...state.forgotPassword, emailFormLoading: false}};

    case SET_PSWD_LOADER:
      return {...state, forgotPassword: {...state.forgotPassword, pswdChangeLoader: true}};
    case UNSET_PSWD_LOADER:
      return {...state, forgotPassword: {...state.forgotPassword, pswdChangeLoader: false}};

    case CHANGE_STEP: {
      return {...state, forgotPassword: {...state.forgotPassword, step: action.payload}};
    }

    case CHANGE_PASSWORD_LOADER:
      return {...state, changePassword: {...state.changePassword, loading: action.payload}};

    case UPDATE_PROFILE_SUCCESS: {
      let {first_name, last_name, mobile_no} = action.payload;
      let authUser = {...state.authUser, first_name, last_name, mobile_no};
      localStorage.setItem('user', JSON.stringify(authUser));
      return {...state, authUser: {...authUser}};
    }

    default:
      return state;
  }
}
