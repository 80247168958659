import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, DatePicker, Form, Input, Row, Select} from "antd";
import {SearchOutlined, CloseOutlined} from '@ant-design/icons';
import moment from "moment";

const {RangePicker} = DatePicker;

export const defaultLicenseFilters = {
  company_name: "",
  license_key: "",
  mobile_no: "",
  email: "",
  address: "",
  no_of_licenses: "",
  approval_status_id: "",
  created_by: "",
  created_on: ""
};

const Filters = props => {
  const [form] = Form.useForm();

  const onSubmit = values => {
    props.onSubmit(values);
  };

  const onResetClick = () => {
    form.setFieldsValue(defaultLicenseFilters);
    props.onSubmit(defaultLicenseFilters);
  };

  return (
    <Form name='filter-form' layout='vertical' onFinish={onSubmit} initialValues={props.initialValues} form={form}>

      <Form.Item name='company_name' label='Company Name'>
        <Input placeholder='Enter company name here'/>
      </Form.Item>

      <Form.Item name='license_key' label='License Key'>
        <Input placeholder='Enter license key here'/>
      </Form.Item>

      <Form.Item name='mobile_no' label='Mobile No'>
        <Input type='number' placeholder='Enter mobile number here'/>
      </Form.Item>

      <Form.Item name='email' label='Email'>
        <Input placeholder='Enter email address here'/>
      </Form.Item>

      {/*<Form.Item name='address' label='Address'>*/}
      {/*  <Input/>*/}
      {/*</Form.Item>*/}

      <Form.Item name='no_of_licenses' label='No. of License(s)'>
        <Input type='number' placeholder='ENter no of licenses here'/>
      </Form.Item>

      <Form.Item name='approval_status_id' label='Status'>
        <Select placeholder='Select status' allowClear>
          <Select.Option value='1'>Pending</Select.Option>
          <Select.Option value='2'>Approved</Select.Option>
          <Select.Option value='3'>Declined</Select.Option>
          <Select.Option value='4'>Open</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item name='created_by' label='Created By'>
        <Input placeholder='Enter created by user name here'/>
      </Form.Item>

      <Form.Item name='date_range' label='Created Between'>
        <RangePicker format="YYYY-MM-DD" disabledDate={(current) => current && current > moment().endOf('day')}
                     style={{width: '100%'}}/>
      </Form.Item>


      <Row>
        <Col>
          <Button type='default' htmlType='submit'><SearchOutlined/>Search</Button>
          <Button type='default' htmlType='button' onClick={onResetClick}><CloseOutlined/>Reset</Button>
        </Col>
      </Row>
    </Form>
  );
};

Filters.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  // onReset: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default Filters;
