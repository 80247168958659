import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}sample`} component={asyncComponent(() => import('./SamplePage'))}/>
      <Route path={`${match.url}home`} component={asyncComponent(() => import('./HomePage'))}/>
      <Route path={`${match.url}licenses/add`} exact={true} component={asyncComponent(() => import('./LicensePage/Add'))}/>
      <Route path={`${match.url}licenses/:id`} exact={true} component={asyncComponent(() => import('./LicensePage/EditLicense'))}/>
      <Route path={`${match.url}licenses`} component={asyncComponent(() => import('./LicensePage'))}/>
      <Route path={`${match.url}partners/add`} component={asyncComponent(() => import('./PartnerPage/AddPartner'))}/>
      <Route path={`${match.url}partners/report/:id`} component={asyncComponent(() => import('./PartnerPage/AuditReport'))}/>
      <Route path={`${match.url}partners-manager/add/:id`} component={asyncComponent(() => import('./PartnerPage/AddPartnerManager'))}/>
      <Route path={`${match.url}partners`}  component={asyncComponent(() => import('./PartnerPage'))}/>
      <Route path={`${match.url}activity`} component={asyncComponent(() => import('./ActivityPage'))}/>
      <Route path={`${match.url}users/project-manager`} exact component={asyncComponent(() => import('./UserPage/ProjectManager'))}/>
      <Route path={`${match.url}users`} component={asyncComponent(() => import('./UserPage'))}/>
      <Route path={`${match.url}user-profile/transfer`} exact component={asyncComponent(() => import('./ProfilePage/TransferUser'))}/>
      <Route path={`${match.url}user-profile`} component={asyncComponent(() => import('./ProfilePage'))}/>
      <Route path={`${match.url}player-password`} component={asyncComponent(() => import('./PlayerPassword'))}/>
    </Switch>
  </div>
);

export default App;
