import {combineReducers} from "redux";
import {connectRouter} from 'connected-react-router'
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import Licenses from './Licenses';
import Activity from "./Activity";
import Partner from "./Partner";
import PartnerReport from "./PartnerReport";
import Users from "./Users";
import Dashboard from "./Dashboard";
import PlayerPassword from './GeneratePassword';

export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  common: Common,
  licenses: Licenses,
  activity: Activity,
  partner: Partner,
  partnerReport: PartnerReport,
  users: Users,
  dashboard: Dashboard,
  playerPassword: PlayerPassword
});
