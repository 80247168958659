import {
  FETCH_PARTNER_LICENSE_REPORT,
  FETCH_PARTNER_LICENSE_REPORT_COMPLETE,
  FETCH_PARTNER_LICENSE_REPORT_START,
  PARTNER_LICENSE_REPORT_FILTERS_CHANGE, SIGNOUT_USER_SUCCESS
} from "../../constants/ActionTypes";
import {defaultAuditReportFilters} from "../../routes/PartnerPage/AuditReportFilter";

const InitialState = {
  loading: false,
  page: 1,
  results: 10,
  total: 10,
  list: [],
  filters: defaultAuditReportFilters,

};

export default (state = InitialState, {type, payload}) => {
  switch (type) {
    case FETCH_PARTNER_LICENSE_REPORT_START:
      return {...state, loading: true};

    case FETCH_PARTNER_LICENSE_REPORT_COMPLETE:
      return {...state, loading: false};

    case FETCH_PARTNER_LICENSE_REPORT:
      return {...state, total: payload.total, list: payload.data};

    case PARTNER_LICENSE_REPORT_FILTERS_CHANGE:
      return {...state, page: payload.page, results: payload.results, filters: payload.filters};

    case SIGNOUT_USER_SUCCESS:
      return InitialState;
    default:
      return state;
  }
}
