import {ClearPlayerPasswordErrors, PLAYER_PASSWORD_ERRORS} from "../../constants/ActionTypes";

const INITIAL_STATE = {
  errors: [],
  loading:false
};

export default (state=INITIAL_STATE, {type,payload}) => {
  switch (type) {
    case PLAYER_PASSWORD_ERRORS:
      return {...state, errors: payload};

    case ClearPlayerPasswordErrors:
      return {...state, errors: []};

    default:
      return state;
  }
}
