import {
  ADD_AMC_COMPLETE,
  ADD_AMC_ERRORS,
  ADD_AMC_START,
  ADD_AMC_SUCCESS,
  AMC_DRAWER_VISIBILITY,
  APPROVE_DECLINE_DATA, APPROVE_DECLINE_DATA_CLEAR,
  APPROVE_DECLINE_LOADING,
  APPROVE_DECLINE_VISIBILITY,
  CLEAR_ADD_AMC_ERRORS,
  CLEAR_EDIT_LICENSE_ERRORS,
  CLEAR_ERROR_LICENSE,
  CLEAR_INCREASE_LICENSE_ERRORS,
  CLEAR_PARTNER_REMAINING_LICENSES,
  CLEAR_SINGLE_LICENSE_DETAILS,
  CREATE_ERROR_LICENSE, DEACTIVATE_DRAWER_STATUS, DEACTIVATE_LOADER,
  EDIT_LICENSE_ERRORS,
  FETCH_AMC_PERIODS,
  FETCH_LICENSE_TYPES,
  FETCH_LICENSES,
  FETCH_PARTNER_REMAINING_LICENSES,
  FETCH_PARTNERS_FOR_LICENSE,
  FETCH_SINGLE_LICENSE_DETAILS,
  GENERATE_LICENSE_KEY_SUCCESS,
  INCREASE_LICENSE_COMPLETE,
  INCREASE_LICENSE_DRAWER_VISIBILITY,
  INCREASE_LICENSE_ERRORS,
  INCREASE_LICENSE_START,
  INCREASE_LICENSE_SUCCESS,
  LICENSE_DEACTIVATED,
  LICENSE_DETAILS_FETCH_COMPLETE,
  LICENSE_DETAILS_FETCH_START,
  LICENSE_PAGINATION_CHANGE,
  LOADING_LICENSES_START,
  LOADING_LICENSES_STOP,
  REPLACE_HW_ERRORS,
  REPLACE_HW_KEY_DRAWER_VISIBILITY,
  REPLACE_HW_LOADING,
  REPLACE_HW_SUCCESS,
  SIGNOUT_USER_SUCCESS, UPDATE_LICENSE_DATA
} from "../../constants/ActionTypes";
import {defaultLicenseFilters} from "../../routes/LicensePage/Filters";

const InitialState = {
  license_page: {
    loading: false,
    list: [],
    total: 10,
    page: 1,
    results: 10,
    filters: defaultLicenseFilters,
    increaseLicenseDrawerVisibility: false,
    updateData: 0,
    addAMC: {drawer: false, loading: false, errors: []},
    replaceHWKey: {drawer: false, loading: false, errors: []},
    approveDecline: {drawer: false, loading: false, data: {}},
    deactivate: {drawer:false, loading: false}
  },
  license_types: [],
  amc_periods: [],
  create_license_errors: [],
  partner_list: [],
  increase_license: {
    partner_remaining_licenses: 0,
    errors: [],
    loading: false,
  },
  edit_license: {loading: false, data: null, errors: []}
};

export default (state = InitialState, {type, payload}) => {
  switch (type) {
    case FETCH_LICENSE_TYPES:
      return {...state, license_types: payload};

    case FETCH_AMC_PERIODS:
      return {...state, amc_periods: payload};

    case LOADING_LICENSES_START:
      return {...state, license_page: {...state.license_page, loading: true}};

    case LOADING_LICENSES_STOP:
      return {...state, license_page: {...state.license_page, loading: false}};

    case FETCH_LICENSES:
      return {...state, license_page: {...state.license_page, list: payload.data, total: payload.total}};

    case LICENSE_PAGINATION_CHANGE:
      return {
        ...state,
        license_page: {...state.license_page, page: payload.page, results: payload.results, filters: payload.filters}
      }

    case CREATE_ERROR_LICENSE:
      return {...state, create_license_errors: payload};

    case CLEAR_ERROR_LICENSE:
      return {...state, create_license_errors: []};

    case FETCH_PARTNERS_FOR_LICENSE:
      return {...state, partner_list: payload};

    case GENERATE_LICENSE_KEY_SUCCESS: {
      let newList = state.license_page.list.filter(item => {
        if (item.license_id === payload.license_id) {
          item.license_key = payload.license_key;
          return item;
        }
        return item;
      });
      return {...state, license_page: {...state.license_page, list: newList}};
    }

    case FETCH_PARTNER_REMAINING_LICENSES:
      return {...state, increase_license: {...state.increase_license, partner_remaining_licenses: payload}};

    case CLEAR_PARTNER_REMAINING_LICENSES:
      return {...state, increase_license: {...state.increase_license, partner_remaining_licenses: 0}};

    case INCREASE_LICENSE_START:
      return {...state, increase_license: {...state.increase_license, loading: true}};

    case INCREASE_LICENSE_COMPLETE:
      return {...state, increase_license: {...state.increase_license, loading: false}};

    case INCREASE_LICENSE_ERRORS:
      return {...state, increase_license: {...state.increase_license, errors: payload}};

    case CLEAR_INCREASE_LICENSE_ERRORS:
      return {...state, increase_license: {...state.increase_license, errors: []}};

    case INCREASE_LICENSE_DRAWER_VISIBILITY:
      return {...state, license_page: {...state.license_page, increaseLicenseDrawerVisibility: payload}};

    case INCREASE_LICENSE_SUCCESS:
    case UPDATE_LICENSE_DATA:
      return {...state, license_page: {...state.license_page, updateData: (state.license_page.updateData + 1)}};

    case FETCH_SINGLE_LICENSE_DETAILS:
      return {...state, edit_license: {...state.edit_license, data: payload}};

    case CLEAR_SINGLE_LICENSE_DETAILS:
      return {...state, edit_license: {...state.edit_license, data: null}};

    case LICENSE_DETAILS_FETCH_START:
      return {...state, edit_license: {...state.edit_license, loading: true}};
    case LICENSE_DETAILS_FETCH_COMPLETE:
      return {...state, edit_license: {...state.edit_license, loading: false}};
    case EDIT_LICENSE_ERRORS:
      return {...state, edit_license: {...state.edit_license, errors: payload}};
    case CLEAR_EDIT_LICENSE_ERRORS:
      return {...state, edit_license: {...state.edit_license, errors: []}};

    case LICENSE_DEACTIVATED: {
      let newList = state.license_page.list.filter(item => {

        if (item.license_id === payload) {
          item.approval_status_id = 1;
          return item;
        }
        return item;
      });

      return {...state, license_page: {...state.license_page, list: newList}};
    }

    case AMC_DRAWER_VISIBILITY:
      return {...state, license_page: {...state.license_page, addAMC: {...state.license_page.addAMC, drawer: payload}}};
    case ADD_AMC_START:
      return {...state, license_page: {...state.license_page, addAMC: {...state.license_page.addAMC, loading: true}}};
    case ADD_AMC_COMPLETE:
      return {...state, license_page: {...state.license_page, addAMC: {...state.license_page.addAMC, loading: false}}};
    case ADD_AMC_ERRORS:
      return {...state, license_page: {...state.license_page, addAMC: {...state.license_page.addAMC, errors: payload}}};
    case CLEAR_ADD_AMC_ERRORS:
      return {...state, license_page: {...state.license_page, addAMC: {...state.license_page.addAMC, errors: []}}};
    case ADD_AMC_SUCCESS:
      return {
        ...state,
        license_page: {
          ...state.license_page,
          addAMC: {...state.license_page.addAMC, drawer: false},
          updateData: (state.license_page.updateData + 1)
        }
      };

    case REPLACE_HW_KEY_DRAWER_VISIBILITY:
      return {
        ...state,
        license_page: {...state.license_page, replaceHWKey: {...state.license_page.replaceHWKey, drawer: payload}}
      };
    case REPLACE_HW_LOADING:
      return {
        ...state,
        license_page: {...state.license_page, replaceHWKey: {...state.license_page.replaceHWKey, loading: payload}}
      }
    case REPLACE_HW_SUCCESS:
      return {...state, license_page: {...state.license_page, updateData: (state.license_page.updateData + 1)}};
    case REPLACE_HW_ERRORS:
      return {
        ...state,
        license_page: {...state.license_page, replaceHWKey: {...state.license_page.replaceHWKey, errors: payload}}
      }

    case APPROVE_DECLINE_VISIBILITY:
      return {
        ...state,
        license_page: {...state.license_page, approveDecline: {...state.license_page.approveDecline, drawer: payload}}
      };

    case APPROVE_DECLINE_LOADING:
      return {
        ...state,
        license_page: {...state.license_page, approveDecline: {...state.license_page.approveDecline, loading: payload}}
      };

    case APPROVE_DECLINE_DATA: {
      return {
        ...state,
        license_page: {...state.license_page, approveDecline: {...state.license_page.approveDecline, data: payload}}
      };
    }

    case DEACTIVATE_DRAWER_STATUS: {
      return {...state, license_page: {...state.license_page, deactivate: {...state.license_page.deactivate, drawer: payload}}}
    }

    case DEACTIVATE_LOADER: {
      return {...state, license_page: {...state.license_page, deactivate: {...state.license_page.deactivate, loading: payload}}}
    }

    case SIGNOUT_USER_SUCCESS:
      return InitialState;

    default:
      return state;
  }
}
