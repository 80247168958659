import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form, Input, Row, Select, DatePicker} from "antd";
import {CloseOutlined, SearchOutlined} from "@ant-design/icons";
import moment from "moment";

export const defaultUserFilters = {user_name:'',user_type_id:'',mobile_no:'',email_id:'',partner_name:'',date_range:null};


const UserFilters = (props) => {
  const [form] = Form.useForm();

  const onReset = () => {
    form.setFieldsValue(defaultUserFilters);
    props.onSubmit(defaultUserFilters);
  };

  return (
    <Form onFinish={values => props.onSubmit(values)} layout='vertical' initialValues={props.initialValues} form={form}>
      <Form.Item name='user_name' label='Name'>
        <Input placeholder='Enter name here'/>
      </Form.Item>
      <Form.Item name='user_type_id' label='User Type'>
        <Select allowClear placeholder='Select user type'>
          {props.userTypes.map((item,index) => (
            <Select.Option key={index} value={item.user_type_id}>{item.user_type}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name='mobile_no' label='Mobile No.'>
        <Input placeholder='Enter mobile number here'/>
      </Form.Item>
      <Form.Item name='email_id' label='Email'>
        <Input placeholder='Enter email address here'/>
      </Form.Item>
      <Form.Item name='partner_name' label='Partner'>
        <Input placeholder='Enter partner name here'/>
      </Form.Item>
      <Form.Item name='date_range' label='Created Between'>
        <DatePicker.RangePicker format="YYYY-MM-DD" disabledDate={(current) => current && current > moment().endOf('day')}
        style={{width:'100%'}}/>
      </Form.Item>

      <Row>


        <Col style={{marginTop:5}}>
          <Button type='default' htmlType='submit'><SearchOutlined/>Search</Button>
          <Button type='default' htmlType='button' onClick={onReset}><CloseOutlined/>Reset</Button>
        </Col>
      </Row>
    </Form>
  );
};

UserFilters.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  // onReset: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  userTypes: PropTypes.array.isRequired
}
export default UserFilters;
