import axios from 'axios';

export const version = `1.2.0`;
// export const backend = "http://192.168.10.198:3005"; //for development
// export const backend = "http://192.168.60.66:3010"; //for testing
export const backend = "https://lms.xtreme-media.com:2443"; //for production

export default axios.create({
  baseURL: backend,
  headers: {
    'Content-Type': 'application/json',
  }
});
