// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';
export const CHANGE_STEP = 'CHANGE_STEP';
export const SET_EMAILFORM_LOADER = 'SET_EMAILFORM_LOADER';
export const UNSET_EMAILFORM_LOADER = 'UNSET_EMAILFORM_LOADER';
export const SET_PSWD_LOADER = 'SET_PSWD_LOADER';
export const UNSET_PSWD_LOADER = 'UNSET_PSWD_LOADER';
export const CHANGE_PASSWORD_LOADER = 'CHANGE_PASSWORD_LOADER';
export const UPDATE_PROFILE_LOADING = 'UPDATE_PROFILE_LOADING';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const PROJECT_MANAGERS_LIST = 'PROJECT_MANAGERS_LIST';
export const TRANSFER_LOADING = 'TRANSFER_LOADING';

export const FETCH_LICENSE_TYPES = 'FETCH_LICENSE_TYPES';
export const FETCH_AMC_PERIODS = 'FETCH_AMC_PERIODS';
export const LOADING_LICENSES_START = 'LOADING_LICENSES_START';
export const LOADING_LICENSES_STOP = 'LOADING_LICENSES_STOP';
export const LICENSE_PAGINATION_CHANGE = 'LICENSE_PAGINATION_CHANGE';
export const FETCH_LICENSES = 'FETCH_LICENSES';
export const CREATE_ERROR_LICENSE = 'CREATE_ERROR_LICENSE';
export const CLEAR_ERROR_LICENSE = 'CLEAR_ERROR_LICENSE';
export const FETCH_PARTNERS_FOR_LICENSE = 'FETCH_PARTNERS_FOR_LICENSE';
export const GENERATE_LICENSE_KEY_SUCCESS = 'GENERATE_LICENSE_KEY_SUCCESS';
export const FETCH_PARTNER_REMAINING_LICENSES = 'FETCH_PARTNER_REMAINING_LICENSES';
export const CLEAR_PARTNER_REMAINING_LICENSES = 'CLEAR_PARTNER_REMAINING_LICENSES';
export const INCREASE_LICENSE_START = 'INCREASE_LICENSE_START';
export const INCREASE_LICENSE_COMPLETE = 'INCREASE_LICENSE_COMPLETE';
export const INCREASE_LICENSE_ERRORS = 'INCREASE_LICENSE_ERRORS';
export const CLEAR_INCREASE_LICENSE_ERRORS = 'CLEAR_INCREASE_LICENSE_ERRORS';
export const INCREASE_LICENSE_DRAWER_VISIBILITY = 'INCREASE_LICENSE_DRAWER_VISIBILITY';
export const INCREASE_LICENSE_SUCCESS = 'INCREASE_LICENSE_SUCCESS';
export const FETCH_SINGLE_LICENSE_DETAILS = 'FETCH_SINGLE_LICENSE_DETAILS';
export const CLEAR_SINGLE_LICENSE_DETAILS = 'CLEAR_SINGLE_LICENSE_DETAILS';
export const LICENSE_DETAILS_FETCH_START = 'LICENSE_DETAILS_FETCH_START';
export const LICENSE_DETAILS_FETCH_COMPLETE = 'LICENSE_DETAILS_FETCH_COMPLETE';
export const CLEAR_LICENSE_DETAILS = 'CLEAR_LICENSE_DETAILS';
export const LICENSE_DETAILS_FETCH_FAILED = 'LICENSE_DETAILS_FETCH_FAILED';
export const LICENSE_DEACTIVATED = 'LICENSE_DEACTIVATED';
export const EDIT_LICENSE_ERRORS = 'EDIT_LICENSE_ERRORS';
export const CLEAR_EDIT_LICENSE_ERRORS = 'CLEAR_EDIT_LICENSE_ERRORS';
export const AMC_DRAWER_VISIBILITY = 'AMC_DRAWER_VISIBILITY';
export const ADD_AMC_ERRORS = 'ADD_AMC_ERRORS';
export const ADD_AMC_START = 'ADD_AMC_START';
export const ADD_AMC_COMPLETE = 'ADD_AMC_COMPLETE';
export const CLEAR_ADD_AMC_ERRORS = 'CLEAR_ADD_AMC_ERRORS';
export const ADD_AMC_SUCCESS = 'ADD_AMC_SUCCESS';
export const REPLACE_HW_KEY_DRAWER_VISIBILITY = 'REPLACE_HW_KEY_DRAWER_VISIBILITY';
export const REPLACE_HW_LOADING = 'REPLACE_HW_LOADING';
export const REPLACE_HW_SUCCESS = 'REPLACE_HW_SUCCESS';
export const REPLACE_HW_ERRORS = 'REPLACE_HW_ERRORS';
export const APPROVE_DECLINE_VISIBILITY = 'APPROVE_DECLINE_VISIBILITY';
export const APPROVE_DECLINE_LOADING = 'APPROVE_DECLINE_LOADING';
export const APPROVE_DECLINE_DATA = 'APPROVE_DECLINE_DATA';
export const UPDATE_LICENSE_DATA = 'UPDATE_LICENSE_DATA';
export const DEACTIVATE_DRAWER_STATUS = 'DEACTIVATE_DRAWER_STATUS';
export const DEACTIVATE_LOADER = 'DEACTIVATE_LOADER';


export const FETCH_PARTNER_LICENSE_REPORT = 'FETCH_PARTNER_LICENSE_REPORT';
export const FETCH_PARTNER_LICENSE_REPORT_START = 'FETCH_PARTNER_LICENSE_REPORT_START';
export const FETCH_PARTNER_LICENSE_REPORT_COMPLETE = 'FETCH_PARTNER_LICENSE_REPORT_COMPLETE';
export const PARTNER_LICENSE_REPORT_FILTERS_CHANGE = 'PARTNER_LICENSE_REPORT_FILTERS_CHANGE';


export const ACTIVITY_LOADING_START = 'ACTIVITY_LOADING_START';
export const FETCH_ACTIVITIES = 'ACTIVITY_FETCH_START';
export const ACTIVITY_LOADING_COMPLETE = 'ACTIVITY_LOADING_COMPLETE';
export const FETCH_USERTYPES = 'FETCH_USERTYPES';
export const ACTIVITY_FILTER_CHANGES = 'ACTIVITY_FILTER_CHANGES';

export const CREATE_PARTNER_ERROR = 'CREATE_PARTNER_ERROR';
export const CLEAR_PARTNER_ERROR = 'CLEAR_PARTNER_ERROR';
export const FETCH_PARTNER_LIST = 'FETCH_PARTNER_LIST';
export const PARTNER_FILTER_CHANGE = 'PARTNER_FILTER_CHANGE';
export const FETCH_PARTNER_LIST_START = 'FETCH_PARTNER_LIST_START';
export const FETCH_PARTNER_LIST_COMPLETE = 'FETCH_PARTNER_LIST_COMPLETE';
export const UPDATE_LICENSES_START = 'UPDATE_LICENSES_START';
export const UPDATE_LICENSES_SUCCESS = 'UPDATE_LICENSES_SUCCESS';
export const UPDATE_LICENSES_COMPLETE = 'UPDATE_LICENSES_COMPLETE';
export const UPDATE_LICENSES_ERROR = 'UPDATE_LICENSES_ERROR';
export const CLEAR_UPDATE_LICENSES_ERROR = 'CLEAR_UPDATE_LICENSES_ERROR';
export const UPDATE_LICENSES_DRAWER_VISIBILITY = 'UPDATE_LICENSES_DRAWER_VISIBILITY';
export const CHANGE_PARTNER_DETAILS_DRAWER_VISIBILITY = 'CHANGE_PARTNER_DETAILS_DRAWER_VISIBILITY';
export const PARTNER_DETAILS_LOADER = 'PARTNER_DETAILS_LOADER';
export const PARTNER_DETAILS_DATA = 'PARTNER_DETAILS_DATA';
export const LICENSE_APPROVAL_COMPLETE = 'LICENSE_APPROVAL_COMPLETE';

export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_COMPLETE = 'CREATE_USER_COMPLETE';
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CLEAR_CREATE_USER_ERRORS = 'CLEAR_CREATE_USER_ERRORS';
export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_COMPLETE = 'FETCH_USERS_COMPLETE';
export const FETCH_USERS_LIST = 'FETCH_USERS_LIST';
export const USERS_PAGE_FILTERS_CHANGE = 'USERS_PAGE_FILTERS_CHANGE';

export const RECENT_ACTIVITIES = 'RECENT_ACTIVITIES';
export const RECENT_TRANSACTIONS = 'RECENT_TRANSACTIONS';
export const REMAINING_LICENSE_BALANCE = 'REMAINING_LICENSE_BALANCE';
export const PENDING_APPROVAL_COUNT = 'PENDING_APPROVAL_COUNT';

export const PLAYER_PASSWORD_ERRORS = 'PLAYER_PASSWORD_ERRORS';
export const ClearPlayerPasswordErrors = 'ClearPlayerPasswordErrors';

