import React from "react";
import {useSelector} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

const SidebarContent = () => {

  let {navStyle, themeType} = useSelector(({settings}) => settings);
  let {pathname} = useSelector(({common}) => common);

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  return (<>

      <SidebarLogo/>
      <div className="gx-sidebar-content">
        {/*<div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>*/}
        {/*  <UserProfile/>*/}

        {/*</div>*/}

        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">

            <Menu.Item key="home">
              <Link to="/home"><i className="icon icon-home"/>
                <span><IntlMessages id="sidebar.homePage"/></span>
              </Link>
            </Menu.Item>

            <Menu.Item key="licenses">
              <Link to="/licenses"><i className="icon icon-keyboard"/>
                <span><IntlMessages id="sidebar.licensePage"/></span>
              </Link>
            </Menu.Item>

            <Menu.Item key="partners">
              <Link to="/partners"><i className="icon icon-team"/>
                <span><IntlMessages id="sidebar.partnerPage"/></span>
              </Link>
            </Menu.Item>

            <Menu.Item key="users">
              <Link to="/users"><i className="icon icon-user-o"/>
                <span><IntlMessages id="sidebar.userPage"/></span>
              </Link>
            </Menu.Item>

            <Menu.Item key="player-password">
              <Link to="/player-password"><i className="icon icon-lock-screen"/>
                <span><IntlMessages id="sidebar.playerPasswordPage"/></span>
              </Link>
            </Menu.Item>

            <Menu.Item key="activity">
              <Link to="/activity"><i className="icon icon-notification-new"/>
                <span><IntlMessages id="sidebar.activityPage"/></span>
              </Link>
            </Menu.Item>


          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};

export default SidebarContent;

