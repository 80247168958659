import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form, Input, Row} from "antd";
import {CloseOutlined, SearchOutlined} from "@ant-design/icons";

export const defaultPartnerFilter = {partner_name:'',parent_company:''};

const PartnerFilterForm = (props) => {
  const [form] = Form.useForm();

  const onReset = () => {
    form.setFieldsValue(defaultPartnerFilter);
    props.onSubmit(defaultPartnerFilter);
  };

  return (
    <Form onFinish={values => props.onSubmit(values)} layout='vertical' initialValues={props.initialValues} form={form}>

      <Form.Item name='partner_name' label='Partner Name'>
        <Input/>
      </Form.Item>

      <Form.Item name='parent_company' label='Partner Company'>
        <Input/>
      </Form.Item>

      <Row>
        <Col style={{marginTop:5}}>
          <Button type='default' htmlType='submit'><SearchOutlined/>Search</Button>
          <Button type='default' htmlType='button' onClick={onReset}><CloseOutlined/>Reset</Button>
        </Col>
      </Row>

    </Form>
  );
};

PartnerFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired
}

export default PartnerFilterForm;
