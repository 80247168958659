export const passwordRegex = {
  pattern: new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,16}$/),
  // message: 'Password should contain alphabets, numbers characters and combination of uppercase, lowercase and special Characters like #?!@$%^&*-'
  message: 'Password should be min 8 and max 16 alphanumeric characters with combination of Uppercase, Lowercase and Special Characters like #?!@$%^&*-'
};
export const firstNameRules = [
  {required: true, message: 'First name is required'},
  {min: 2, max: 45, message: 'Min 2 and max 45 characters allowed'},
  {pattern: new RegExp(/^[a-zA-Z]+$/), message: 'Only alphabets allowed'}
];
export const lastNameRules = [
  {required: true, message: 'Last name is required'},
  {min: 2, max: 45, message: 'Min 2 and max 45 characters allowed'},
  {pattern: new RegExp(/^[a-zA-Z]+$/), message: 'Only alphabets allowed'}
];
export const mobileNoRules = [
  {required: true, message: 'Mobile No is required'},
  {pattern: new RegExp(/^[6-9][0-9]{9}$/), message: 'Mobile No must be valid and numeric'}
];
export const emailRules = [{required: true, type: 'email', message: 'Please enter valid email'}];

export const remarkRules = [
  {required: true, message: 'Remark is required'},
  {min: 4, max: 90, message: 'Remark must be min 4 and max 90 characters long'},
  {pattern: new RegExp(/^[a-zA-Z0-9 \-().,]+$/), max: 90, message: 'Remark must be alphanumeric and can contain special symbols like . , - allowed'}
];
