import {
  CHANGE_PASSWORD_LOADER,
  CHANGE_STEP,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL, SET_EMAILFORM_LOADER, SET_PSWD_LOADER,
  SIGNOUT_USER_SUCCESS, UNSET_EMAILFORM_LOADER, UNSET_PSWD_LOADER,
  USER_DATA,
  USER_TOKEN_SET
} from "../../constants/ActionTypes";
import axios from "../../util/Api";
import {message} from "antd";
import {history} from "../store";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = ({email, password, name}) => {
  console.log(email, password);
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/register', {
        email: email,
        password: password,
        name: name
      }
    ).then(({data}) => {
      console.log("data:", data);
      if (data.result) {
        localStorage.setItem("token", JSON.stringify(data.token.access_token));
        axios.defaults.headers.common['authorization'] = "Bearer " + data.token.access_token;
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
        dispatch({type: USER_DATA, payload: data.user});
      } else {
        console.log("payload: data.error", data.error);
        dispatch({type: FETCH_ERROR, payload: "Network Error"});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const userSignIn = ({email, password}) => dispatch => {
  dispatch({type: FETCH_START});
  axios.post('auth/signin', {email_id: email, password: password,}).then(({data}) => {
    localStorage.setItem("token", JSON.stringify(data.access_token));
    localStorage.setItem("user", JSON.stringify(data.user));

    axios.defaults.headers.common['Authorization'] = data.access_token;

    dispatch({type: FETCH_SUCCESS});
    dispatch({type: USER_TOKEN_SET, payload: data.access_token});
    dispatch({type: USER_DATA, payload: data.user});
  }).catch(function (error) {
    dispatch({type: FETCH_SUCCESS});
    if (error.response && (error.response.status === 401 || error.response.status === 422)) {
      message.error(error.response.data.data.errors[0].msg);
    } else {
      message.error(error.message);
    }
  });
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/me',
    ).then(({data}) => {
      console.log("userSignIn: ", data);
      if (data.result) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_DATA, payload: data.user});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};


export const userSignOut = () => {

  return (dispatch) => {
    dispatch({type: FETCH_START});

    localStorage.removeItem("token");
    localStorage.removeItem("user");


    axios.post('auth/signout').then(() => {
      delete axios.defaults.headers.common["Authorization"];

      dispatch({type: FETCH_SUCCESS});
      dispatch({type: SIGNOUT_USER_SUCCESS});

    }).catch(function (error) {
      delete axios.defaults.headers.common["Authorization"];

      console.log("Error****:", error.message);
      dispatch({type: FETCH_SUCCESS});
      dispatch({type: SIGNOUT_USER_SUCCESS});
    });
  }
};

export const sendOTP = email => (dispatch) => {
  dispatch({type: FETCH_START});
  dispatch({type: SET_EMAILFORM_LOADER});

  axios.post('/auth/generate-otp', {email_id: email}).then(() => {
    dispatch({type: FETCH_SUCCESS});
    dispatch({type: UNSET_EMAILFORM_LOADER});
    dispatch({type: CHANGE_STEP, payload: 1});
    message.success('OTP sent successfully');
  }).catch(error => {
    console.error('Error while sending password otp: ', error);
    dispatch({type: UNSET_EMAILFORM_LOADER});
    if (error.response && error.response.status === 401) {
      message.error(error.response.data.data.errors[0].msg);
    } else {
      message.error(error.message);
    }
  });
};

export const changeEmail = () => {
  return {type: CHANGE_STEP, payload: 0};
};

export const resetPassword = values => dispatch => {
  dispatch({type: SET_PSWD_LOADER});
  axios.post('/auth/reset-password', values).then(() => {
    dispatch({type: UNSET_PSWD_LOADER});
    message.success('Password changed successfully');
    history.push('/signin');
  }).catch(error => {
    console.error('Error while changing password: ', error);
    dispatch({type: UNSET_PSWD_LOADER});

    if (error.response && error.response.status === 422) {
      message.error(error.response.data.data.errors[0].msg);
    } else {
      message.error(error.message);
    }
  });
};

export const changePassword = values => dispatch => {
  dispatch({type: CHANGE_PASSWORD_LOADER, payload: true});

  axios.post('/new-password', values).then(() => {
    dispatch({type: CHANGE_PASSWORD_LOADER, payload: false});
    message.success('Password changed successfully');
  }).catch(error => {
    dispatch({type: CHANGE_PASSWORD_LOADER, payload: false});

    let errorMessage = 'Something went wrong';
    if (error.response && error.response.status === 422) {
      let data = error.response.data;
      console.error('Error while changing password: ', data);
      if (data.data && data.data.errors && data.data.errors.length > 0)
        errorMessage = data.data.errors[0].msg;
    }

    message.error(errorMessage);
  })
};


