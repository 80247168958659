import React from 'react';
import PropTypes from 'prop-types';
import {Form, Button, Input} from "antd";
import {emailRules} from "../../../constants/Validations";

const EmailForm = props => {
    const onSubmit = values => {
        props.onSubmit(values);
    };

    return (
        <div className="gx-mx-3 gx-my-4">
            <p style={{textAlign: "center"}}>We'll send a verification code on your email id for password recovery</p>

            <Form name='email-form' onFinish={onSubmit} initialValues={props.initialValues}>
                <Form.Item name='email' rules={emailRules}>
                    <Input type='email' placeholder='Enter email'/>
                </Form.Item>

                <Form.Item>
                    <Button style={{float: 'right'}} type="primary" htmlType="submit" loading={props.loading}>
                        Send OTP
                    </Button>
                </Form.Item>
            </Form>

        </div>
    );
};

EmailForm.defaultProps = {
    initialValues: {},
    loading: false
};
EmailForm.propTypes = {
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool
}
export default EmailForm;
