import {
  ACTIVITY_FILTER_CHANGES,
  ACTIVITY_LOADING_COMPLETE,
  ACTIVITY_LOADING_START,
  FETCH_ACTIVITIES,
  FETCH_USERTYPES, SIGNOUT_USER_SUCCESS
} from "../../constants/ActionTypes";
import {defaultActivityFilters} from "../../routes/ActivityPage/Filters";

const InitialState = {
  loading: false,
  list: [],
  page: 0,
  total: 0,
  results: 10,
  filters: defaultActivityFilters,
  user_types: []
};

export default (state = InitialState, {type, payload}) => {
  switch (type) {
    case ACTIVITY_LOADING_START:
      return {...state, loading: true};

    case ACTIVITY_LOADING_COMPLETE:
      return {...state, loading: false};

    case FETCH_ACTIVITIES:
      return {...state, list: payload.data, total: payload.total};

    case FETCH_USERTYPES:
      return {...state, user_types: payload};

    case ACTIVITY_FILTER_CHANGES:
      return {...state, page: payload.page, results: payload.results, filters: payload.filters};

    case SIGNOUT_USER_SUCCESS:
      return InitialState;
    default:
      return state;
  }

}
