import axios from '../../util/Api';
import { history } from '../store'
import {
  CLEAR_ERROR_LICENSE,
  CLEAR_INCREASE_LICENSE_ERRORS,
  CLEAR_PARTNER_REMAINING_LICENSES,
  CREATE_ERROR_LICENSE,
  FETCH_AMC_PERIODS,
  FETCH_SINGLE_LICENSE_DETAILS,
  FETCH_LICENSE_TYPES,
  FETCH_LICENSES,
  FETCH_PARTNER_REMAINING_LICENSES,
  FETCH_PARTNERS_FOR_LICENSE,
  FETCH_START,
  FETCH_SUCCESS,
  GENERATE_LICENSE_KEY_SUCCESS,
  INCREASE_LICENSE_COMPLETE,
  INCREASE_LICENSE_DRAWER_VISIBILITY,
  INCREASE_LICENSE_ERRORS,
  INCREASE_LICENSE_START,
  INCREASE_LICENSE_SUCCESS,
  LICENSE_PAGINATION_CHANGE,
  LOADING_LICENSES_START,
  LOADING_LICENSES_STOP,
  CLEAR_SINGLE_LICENSE_DETAILS,
  LICENSE_DETAILS_FETCH_START,
  LICENSE_DETAILS_FETCH_COMPLETE,
  LICENSE_DEACTIVATED,
  EDIT_LICENSE_ERRORS,
  CLEAR_EDIT_LICENSE_ERRORS,
  AMC_DRAWER_VISIBILITY,
  CLEAR_ADD_AMC_ERRORS,
  ADD_AMC_START,
  ADD_AMC_COMPLETE,
  ADD_AMC_ERRORS,
  ADD_AMC_SUCCESS,
  REPLACE_HW_KEY_DRAWER_VISIBILITY,
  REPLACE_HW_LOADING,
  REPLACE_HW_SUCCESS,
  REPLACE_HW_ERRORS,
  APPROVE_DECLINE_VISIBILITY,
  APPROVE_DECLINE_LOADING,
  APPROVE_DECLINE_DATA, UPDATE_LICENSE_DATA, DEACTIVATE_DRAWER_STATUS, DEACTIVATE_LOADER,
} from "../../constants/ActionTypes";
import { message } from "antd";
import { saveAs } from "file-saver"

export const fetchLicenseTypes = () => (dispatch) => {
  axios.get('/license-types').then(({ data }) => {
    dispatch({ type: FETCH_LICENSE_TYPES, payload: data });
  }).catch(error => {
    console.error('Error while fetching license types: ', error);
  })
};

export const fetchAMCPeriods = () => (dispatch) => {
  axios.get('/amc-time').then(({ data }) => {
    dispatch({ type: FETCH_AMC_PERIODS, payload: data });
  }).catch(error => {
    console.error('Error while fetching AMC period details: ', error);
  })
};

export const createLicense = values => (dispatch) => {
  dispatch({ type: FETCH_START });
  axios.post('/license', values).then(() => {
    dispatch({ type: FETCH_SUCCESS });
    message.success('License created successfully');
    history.push('/licenses');
  }).catch(error => {
    dispatch({ type: FETCH_SUCCESS });
    if (error.response && error.response.status === 422) {
      console.log('Validation error: ', error.response.data.data.errors);
      dispatch({ type: CREATE_ERROR_LICENSE, payload: error.response.data.data.errors })
    } else {
      console.log('Internal server error', error);
      message.error('Something went wrong');
    }
  })
};

export const fetchLicenses = (filters) => (dispatch) => {
  dispatch({ type: LOADING_LICENSES_START });
  axios.get('/license-list', { params: { ...filters } }).then(({ data }) => {
    dispatch({ type: FETCH_LICENSES, payload: data });
    dispatch({ type: LOADING_LICENSES_STOP });
  }).catch(error => {
    console.error('Error while creating license: ', error);
    dispatch({ type: LOADING_LICENSES_STOP });
    dispatch({ type: FETCH_LICENSES, payload: { total: 0, data: [] } });
    message.error(error.message);
  });
};

export const licenseFiltersChanged = (page, results, filters) => {
  return { type: LICENSE_PAGINATION_CHANGE, payload: { page, results, filters } };
};

export const clearCreateLicenseError = () => {
  return { type: CLEAR_ERROR_LICENSE };
};

export const fetchPartnerListForLicense = () => dispatch => {
  axios.get('/partner-details').then(({ data }) => {
    dispatch({ type: LOADING_LICENSES_STOP });
    dispatch({ type: FETCH_PARTNERS_FOR_LICENSE, payload: data });
  }).catch(error => {
    console.error('Error while fetching partner list for license creation: ', error);
  })
};

export const generateLicenseKey = license_id => dispatch => {
  dispatch({ type: LOADING_LICENSES_START });

  axios.post('/generate-key', { license_id }).then(({ data }) => {
    dispatch({ type: GENERATE_LICENSE_KEY_SUCCESS, payload: { license_id, ...data } });
    dispatch({ type: LOADING_LICENSES_STOP });
  }).catch(error => {
    dispatch({ type: LOADING_LICENSES_STOP });
    let errorMessage = 'Something went wrong';
    if (error.response && error.response.status === 422) {
      let data = error.response.data;
      errorMessage = data.data && data.data.errors && data.data.errors.length > 0 ? data.data.errors[0].msg : errorMessage;
    }
    message.error(errorMessage);
    console.error('Error while generating license key: ', error);
  })
};

export const fetchPartnerRemainingLicenses = partner_id => dispatch => {
  axios.get('/license-remaining', { params: { partner_id } }).then(({ data }) => {
    dispatch({ type: FETCH_PARTNER_REMAINING_LICENSES, payload: data });
  }).catch(error => {
    console.error('Error while fetching remaining licenses of partner: ', error);
  });
};

export const clearPartnerRemainingLicenses = () => {
  return { type: CLEAR_PARTNER_REMAINING_LICENSES };
};

export const increaseLicenses = data => dispatch => {
  dispatch({ type: INCREASE_LICENSE_START });

  axios.post('/increase-license', data).then(() => {
    dispatch({ type: INCREASE_LICENSE_COMPLETE });
    dispatch({ type: INCREASE_LICENSE_DRAWER_VISIBILITY, payload: false });
    dispatch({ type: INCREASE_LICENSE_SUCCESS });
    message.success('License increased successfully');
  }).catch(error => {
    dispatch({ type: INCREASE_LICENSE_COMPLETE });
    if (error.response && error.response.status === 422) {

      dispatch({ type: INCREASE_LICENSE_ERRORS, payload: error.response.data.data.errors });
    } else {
      dispatch({ type: INCREASE_LICENSE_ERRORS, payload: [{ param: '', msg: 'Something went wrong' }] });
    }
    console.error('Error while increasing licenses: ', error);
  })
};

export const clearIncreaseLicenseErrors = () => {
  return { type: CLEAR_INCREASE_LICENSE_ERRORS };
};

export const changeIncreaseLicenseDrawerVisibility = (status) => {
  return { type: INCREASE_LICENSE_DRAWER_VISIBILITY, payload: status };
};

export const downloadLicFile = (pdfData, fileName) => {
  // const linkSource = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(pdfData) : window.webkitURL.createObjectURL(pdfData);;//`data:text/html;base64,${pdfData}`;
  const linkSource = `data:application/json;base64,${pdfData}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName + '.lic';
  downloadLink.click();
};

export const downloadLicenseFileContent = (license_id, fileName) => () => {
  axios.get('/download', { params: { license_id } }).then(({ data }) => {
    downloadLicFile(data, fileName);
  }).catch(error => {
    let errorMessage = 'Something went wrong';
    if (error.response && error.response.status === 422) {
      const responseData = error.response.data.data;
      if (responseData && responseData.errors && responseData.errors.length > 0) {
        errorMessage = responseData.errors[0].msg;
      }
    }
    console.log('Error while downloading file: ', error);
    message.error(errorMessage);
  })
};

export const downloadLicenseCertificate = (license_id, fileName) => () => {
  axios.get('/certificate', { params: { license_id }, responseType: 'blob' }).then(({ data }) => {
    const pdfBlob = new Blob([data], { type: 'application/pdf' })
    saveAs(pdfBlob, fileName + ' License Certificate.pdf')
  }).catch(error => {
    let errorMessage = 'Something went wrong';
    if (error.response && error.response.status === 422) {
      const responseData = error.response.data.data;
      if (responseData && responseData.errors && responseData.errors.length > 0) {
        errorMessage = responseData.errors[0].msg;
      }
    }
    console.log('Error while downloading file: ', error);
    message.error(errorMessage);
  })
};

export const clearLicenseDetails = () => {
  return { type: CLEAR_SINGLE_LICENSE_DETAILS };
};

export const fetchLicenseDetails = license_id => dispatch => {
  dispatch({ type: LICENSE_DETAILS_FETCH_START });

  axios.get('/specific-license-details', { params: { license_id } }).then(({ data }) => {
    dispatch({ type: LICENSE_DETAILS_FETCH_COMPLETE })
    dispatch({ type: FETCH_SINGLE_LICENSE_DETAILS, payload: data });
  }).catch(error => {
    console.error('Error while fetching license details:', error);
    if (error.response && error.response.status === 403) {
      message.error('You can not edit this license.');
      history.push('/licenses');
    } else {
      message.error(error.message);
    }
  })
};

export const editLicenseDetails = values => dispatch => {
  dispatch({ type: LICENSE_DETAILS_FETCH_START });

  axios.post('/edit-license', { ...values }).then(() => {
    dispatch({ type: LICENSE_DETAILS_FETCH_COMPLETE });
    history.push('/licenses');
  }).catch(error => {
    dispatch({ type: LICENSE_DETAILS_FETCH_COMPLETE })
    if (error.response && error.response.status === 422) {
      const errorData = error.response.data;
      if (errorData.data && errorData.data.errors && errorData.data.errors.length > 0) {
        dispatch({ type: EDIT_LICENSE_ERRORS, payload: errorData.data.errors });
      }
    } else {
      message.error('Something went wrong');
      console.error('Error while updating license: ', error);
    }
  })
};

export const clearEditLicenseErrors = () => {
  return { type: CLEAR_EDIT_LICENSE_ERRORS };
};

export const changeAddAMCDrawerVisibility = status => {
  return { type: AMC_DRAWER_VISIBILITY, payload: status };
};

export const addAMCForLicense = values => dispatch => {
  dispatch({ type: ADD_AMC_START });

  axios.post('/amc', values).then(() => {
    dispatch({ type: ADD_AMC_COMPLETE });
    dispatch({ type: ADD_AMC_SUCCESS });
    message.success('AMC added successfully');
  }).catch(error => {
    dispatch({ type: ADD_AMC_COMPLETE });

    if (error.response && error.response.status === 422) {
      const errorResponse = error.response.data;
      if (errorResponse.data && errorResponse.data.errors && errorResponse.data.errors.length > 0) {
        dispatch({ type: ADD_AMC_ERRORS, payload: errorResponse.data.errors })
      }
    } else {
      message.error('Something went wrong');
      console.error('Error while adding amc to license: ', error);
    }

  })
};

export const clearAddAMCErrors = () => {
  return { type: CLEAR_ADD_AMC_ERRORS };
};

export const changeReplaceHWKeyVisibility = status => {
  return { type: REPLACE_HW_KEY_DRAWER_VISIBILITY, payload: status };
};

export const replaceHardwareKey = values => dispatch => {
  dispatch({ type: REPLACE_HW_LOADING, payload: true });

  axios.post('/replace-hardware', values).then(() => {
    dispatch({ type: REPLACE_HW_LOADING, payload: false });
    dispatch({ type: REPLACE_HW_SUCCESS });
    dispatch({ type: REPLACE_HW_KEY_DRAWER_VISIBILITY, payload: false });
    message.success('Hardware key replaced successfully');
  }).catch(error => {
    dispatch({ type: REPLACE_HW_LOADING, payload: false });
    if (error.response && error.response.status === 422) {
      const errorResponse = error.response.data;
      if (errorResponse.data && errorResponse.data.errors && errorResponse.data.errors.length > 0) {
        dispatch({ type: REPLACE_HW_ERRORS, payload: errorResponse.data.errors })
      }
    } else {
      message.error('Something went wrong');
      console.error('Error while adding amc to license: ', error);
    }
  })
};

export const clearReplaceHWErrors = () => {
  return { type: REPLACE_HW_ERRORS, payload: [] };
};

export const changeApproveDeclineVisibility = status => {
  return { type: APPROVE_DECLINE_VISIBILITY, payload: status };
};

export const fetchApprovalDetails = license_id => dispatch => {
  dispatch({ type: APPROVE_DECLINE_LOADING, payload: true });

  axios.get('/approval-details', { params: { license_id } }).then(({ data }) => {
    dispatch({ type: APPROVE_DECLINE_LOADING, payload: false });
    dispatch({ type: APPROVE_DECLINE_DATA, payload: data });
  }).catch(error => {
    dispatch({ type: APPROVE_DECLINE_LOADING, payload: false });
  });
};

export const clearApprovalDetails = () => {
  return { type: APPROVE_DECLINE_DATA, payload: {} };
};

export const approveDeclineLicense = data => dispatch => {
  axios.post('/approve-decline', data).then(() => {
    dispatch({ type: UPDATE_LICENSE_DATA });
    dispatch({ type: APPROVE_DECLINE_VISIBILITY, payload: false });
    message.success('License status updated successfully');
  }).catch(error => {
    let errorMessage = 'Something went wrong';

    if (error.response && error.response.status === 422) {
      const responseData = error.response.data.data;
      if (responseData && responseData.errors && responseData.errors.length > 0) {
        errorMessage = responseData.errors[0].msg;
      }
    }
    message.error(errorMessage);
  });
};

export const changeDeactivateDrawerVisibility = (status) => {
  return { type: DEACTIVATE_DRAWER_STATUS, payload: status };
};

export const deactivateLicense = (license_id, remark) => dispatch => {
  dispatch({ type: DEACTIVATE_LOADER, payload: true });

  axios.post('/deactivate', { license_id, remark }).then(() => {
    dispatch({ type: LICENSE_DEACTIVATED, payload: license_id });
    dispatch({ type: DEACTIVATE_DRAWER_STATUS, payload: false });
    dispatch({ type: DEACTIVATE_LOADER, payload: false });

    message.success('Request submitted successfully');
  }).catch(error => {
    dispatch({ type: DEACTIVATE_LOADER, payload: false });
    let errorMessage = 'Something went wrong';
    if (error.response && error.response.status === 422) {
      const responseData = error.response.data.data;
      if (responseData && responseData.errors && responseData.errors.length > 0) {
        errorMessage = responseData.errors[0].msg;
      }
    }
    console.error('Error while deactivating license: ', error);
    message.error(errorMessage);
  })
};
