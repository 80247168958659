import {
  CHANGE_PARTNER_DETAILS_DRAWER_VISIBILITY,
  CLEAR_PARTNER_ERROR, CREATE_PARTNER_ERROR,
  FETCH_PARTNER_LIST,
  FETCH_PARTNER_LIST_COMPLETE,
  FETCH_PARTNER_LIST_START, LICENSE_APPROVAL_COMPLETE, PARTNER_DETAILS_DATA, PARTNER_DETAILS_LOADER,
  PARTNER_FILTER_CHANGE, SIGNOUT_USER_SUCCESS,
  UPDATE_LICENSES_COMPLETE, UPDATE_LICENSES_DRAWER_VISIBILITY, UPDATE_LICENSES_START, UPDATE_LICENSES_SUCCESS
} from "../../constants/ActionTypes";
import {defaultPartnerFilter} from "../../routes/PartnerPage/FilterForm";

const InitialState = {
  errors: [],
  loading: false,
  page: 1,
  results: 10,
  total: 10,
  list: [],
  filters: defaultPartnerFilter,
  licenseUpdateDrawer: false,
  updateLoader: false,
  updateData: 0,
  partnerDetailsDrawer: {visible: false, loading: false, partnerId: 0, details: null}
};

export default (state = InitialState, {type, payload}) => {
  switch (type) {
    case CREATE_PARTNER_ERROR:
      return {...state, errors: payload};

    case CLEAR_PARTNER_ERROR:
      return {...state, errors: []};

    case FETCH_PARTNER_LIST:
      return {...state, list: payload.data, total: payload.total};

    case FETCH_PARTNER_LIST_START:
      return {...state, loading: true};

    case FETCH_PARTNER_LIST_COMPLETE:
      return {...state, loading: false};

    case PARTNER_FILTER_CHANGE:
      return {...state, page: payload.page, results: payload.results, filters: payload.filters};

    case UPDATE_LICENSES_DRAWER_VISIBILITY:
      return {...state, licenseUpdateDrawer: payload};

    case UPDATE_LICENSES_START:
      return {...state, updateLoader: true};
    case UPDATE_LICENSES_COMPLETE:
      return {...state, updateLoader: false, licenseUpdateDrawer: false};

    case UPDATE_LICENSES_SUCCESS:
      return {...state, updateData: (state.updateData + 1)};

    case CHANGE_PARTNER_DETAILS_DRAWER_VISIBILITY: {
      return {...state, partnerDetailsDrawer: {...state.partnerDetailsDrawer, visible: payload}};
    }

    case PARTNER_DETAILS_LOADER: {
      return {...state, partnerDetailsDrawer: {...state.partnerDetailsDrawer, loading: payload}};
    }
    case PARTNER_DETAILS_DATA: {
      return {...state, partnerDetailsDrawer: {...state.partnerDetailsDrawer, details: payload}};
    }
    case LICENSE_APPROVAL_COMPLETE: {
      return {
        ...state,
        updateData: (state.updateData + 1),
        partnerDetailsDrawer: {...state.partnerDetailsDrawer, visible: false, details: null}
      };
    }


    case SIGNOUT_USER_SUCCESS:
      return InitialState;
    default:
      return state;
  }
};
